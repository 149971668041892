import { useContext, useState, useEffect } from "react";
import { useTransition, animated } from "@react-spring/web";
import AppContext from "../../context/AppContext";
import WorkSwitchButton from "./children/WorkSwitchButton";
import icons from "./children/techIcons";
import "./Work.css";

function Work() {
  const { currentSection } = useContext(AppContext);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [nextProjectName, setNextProjectName] = useState(null);

  const [testProjectArr] = useState([
    {
      name: "SkuProfit",
      description: (
        <p>
          <span>SkuProfit</span> helps Amazon Selling Partners gain critical
          insights on their Amazon sales report data. Harnessing the Amazon
          Selling Partner API, SkuProfit automatically processes seller reports,
          parses them into comprehensive but readable data tables by SKU, and
          generates a profit analysis in order to provide critical insights into
          their Amazon store performance metrics.
        </p>
      ),
      tech: ["react", "typescript", "tailwind", "node", "mySql", "git"],
      thumbs: [
        "https://demicog-bikes.s3.us-west-1.amazonaws.com/skuprofit_home.png",
        "https://demicog-bikes.s3.us-west-1.amazonaws.com/skuprofit_products.png",
        "https://demicog-bikes.s3.us-west-1.amazonaws.com/skuprofit_report.png",
      ],
      linkCode: "https://github.com/stuartErnstsen/demicog",
      linkLive: "https://www.skuprofit.com/",
    },
    {
      name: "VHS",
      description: (
        <p>
          <span>Vedic Heartland Source</span> is a repository for storing and
          searching transcripts of the vedic teaching lectures of Sri Dharma
          Pravartaka Acharya. All transcripts from the Dharma Nation Youtube
          channel are web crawled, aggregated, and indexed by text and timestamp
          for fast searching across hundreds of lectures.
        </p>
      ),
      tech: ["next", "typescript", "sql", "tailwind", "git", "puppet"],
      thumbs: [
        "https://demicog-bikes.s3.us-west-1.amazonaws.com/vhs_home.png",
        "https://demicog-bikes.s3.us-west-1.amazonaws.com/vhs_search.png",
        "https://demicog-bikes.s3.us-west-1.amazonaws.com/vhs_transcript.png",
      ],
      linkCode: "https://github.com/restaurant-tinder-WR8/reastaurant-tinder",
      linkLive: "https://vedic-heartland-source.vercel.app/",
    },
    // {
    //   name: "CHECKERS",
    //   description: (
    //     <p>
    //       <span>CHECKERS</span> was a demonstration app I created when I was
    //       asked by one of my students how I would build checkers within React.
    //       Codesandbox.io is one of my favorite tools for rapid testing and
    //       building a concept. This is one of the most raw examples I can show of
    //       my work, and still one of my favorite to this date. Nothing fancy,
    //       just a proof of concept and fun with code.
    //     </p>
    //   ),
    //   tech: ["html", "css", "js", "react"],
    //   thumbs: [
    //     "https://demicog-bikes.s3.us-west-1.amazonaws.com/BOARD.png",
    //     "https://demicog-bikes.s3.us-west-1.amazonaws.com/CODE.png",
    //     "https://demicog-bikes.s3.us-west-1.amazonaws.com/COMBO.png",
    //   ],
    //   linkCode: "https://codesandbox.io/s/react-checkers-eo7qr",
    //   linkLive: "https://codesandbox.io/s/react-checkers-eo7qr",
    // },
  ]);

  const handleSetNextProject = () => {
    setCurrentProjectIndex((old) => (testProjectArr[old + 1] ? old + 1 : 0));
  };

  //Forces browser to preload images for projects
  useEffect(() => {
    testProjectArr.forEach((proj) => {
      proj.thumbs.forEach((url) => {
        new Image().src = url;
      });
    });
  }, [testProjectArr]);

  useEffect(() => {
    setNextProjectName(
      testProjectArr[currentProjectIndex + 1]?.name || testProjectArr[0].name
    );
  }, [currentProjectIndex, testProjectArr]);

  const infoTransition = useTransition(currentSection, {
    config: {
      duration: 300,
    },
    from: {
      top: "100%",
    },
    enter: {
      top: "0%",
    },
    leave: {
      top: "100%",
    },
  });

  const projectTransitionInLeft = useTransition(currentProjectIndex, {
    config: { duration: 300 },
    from: { right: "-100%" },
    enter: { right: "0%" },
    leave: { right: "100%" },
  });

  const projectTransitionInRight = useTransition(currentProjectIndex, {
    config: { duration: 300 },
    from: { right: "100%" },
    enter: { right: "0%" },
    leave: { right: "-100%" },
  });

  const projectTransitionInBottom = useTransition(currentProjectIndex, {
    config: { duration: 300 },
    from: { top: "100%" },
    enter: { top: "0%" },
    leave: { top: "-100%" },
  });

  return infoTransition(
    (style, currentSection) =>
      currentSection === "work" && (
        <animated.section
          style={style}
          className="content-child-container"
          id="content-work-container"
        >
          <div className="alt-section-background work-section-background"></div>
          <img
            id="background-clouds"
            src="https://demicog-bikes.s3.us-west-1.amazonaws.com/favpng_papercutting-cloud+(2).png"
            alt="clouds"
          />
          <section className="content-work-child" id="content-work-left">
            {projectTransitionInBottom((style, projectIndex) => (
              <animated.section style={style} id="content-work-left-inner">
                {testProjectArr[projectIndex].thumbs.map((el, i) => (
                  <img src={el} alt={`thumb-${i}`} key={el + i} />
                ))}
              </animated.section>
            ))}
          </section>

          <div className="content-work-child" id="content-work-right">
            <section
              className="content-work-right-child"
              id="content-work-detail-container"
            >
              {projectTransitionInRight((style, projectIndex) => (
                <animated.section
                  style={style}
                  id="content-work-detail-container-inner"
                >
                  <div className="detail-child" id="content-work-detail-left">
                    {testProjectArr[projectIndex].description}
                  </div>
                  <div className="y-divider" />
                  <div className="x-divider" />
                  <div className="detail-child" id="content-work-detail-right">
                    {testProjectArr[projectIndex].tech.map((el, i) => (
                      <div
                        className="tech-item-container"
                        key={icons[el].name + i}
                      >
                        {icons[el].component}
                        <p>{icons[el].name}</p>
                      </div>
                    ))}
                  </div>
                </animated.section>
              ))}
            </section>
            <section
              className="content-work-right-child"
              id="content-work-title-container"
            >
              <div className="work-descriptor">
                <p>INFO</p>
              </div>
              <div className="work-descriptor">
                <p>TECH</p>
              </div>

              {projectTransitionInLeft((style, projectIndex) => (
                <animated.section style={style} id="content-work-title-inner">
                  <h2>{testProjectArr[projectIndex].name}</h2>
                  <section className="project-button-container">
                    <a
                      className="project-button"
                      href={testProjectArr[projectIndex].linkLive}
                      target="_blank"
                      rel="noreferrer"
                    >
                      SITE
                    </a>
                    {/* <a
                      className="project-button"
                      href={testProjectArr[projectIndex].linkCode}
                      target="_blank"
                      rel="noreferrer"
                    >
                      CODE
                    </a> */}
                  </section>
                </animated.section>
              ))}
            </section>
            <WorkSwitchButton
              handleSetNextProject={handleSetNextProject}
              nextProjectName={nextProjectName}
            />
          </div>
        </animated.section>
      )
  );
}

export default Work;
