import { useTransition, animated } from '@react-spring/web';
import RedoRoundedIcon from '@material-ui/icons/RedoRounded';

const InfoSwitchButton = (props) => {
    const { setCurrentInfoSection, currentInfoSection } = props

    const rotateOneEighty = useTransition(currentInfoSection, {
        config: { duration: 300 },
        from: {
            transform: 'rotate(-180deg)',
            opacity: 0
        },
        enter: {
            transform: 'rotate(0deg)',
            opacity: 1
        },
        leave: {
            transform: 'rotate(180deg)',
            opacity: 0
        }
    })

    return (
        <button id='switch-button' onClick={() => setCurrentInfoSection(old => old === 'bio' ? 'contact' : 'bio')}>
            {rotateOneEighty((style, currentInfoSection) => (
                currentInfoSection === 'bio' ? (
                    <animated.div className='switch-button-inner-container' style={style} >
                        <RedoRoundedIcon className='switch-arrow' fontSize='large' />
                        <p>CONTACT</p>
                        <RedoRoundedIcon className='switch-arrow alt-switch-arrow' fontSize='large' />

                    </animated.div>
                ) : (
                    <animated.div className='switch-button-inner-container' style={style} >
                        <RedoRoundedIcon className='switch-arrow' fontSize='large' />
                        <p>BIO</p>
                        <RedoRoundedIcon className='switch-arrow alt-switch-arrow' fontSize='large' />

                    </animated.div>
                )
            ))}

        </button>
    )
}

export default InfoSwitchButton;