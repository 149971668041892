import { useState, useRef, useEffect, useCallback } from "react";
import { useTransition } from '@react-spring/web';

const useTransitionContext = () => {
    const [currentSection, setCurrentSection] = useState('home')
    const loadingRef = useRef(false)
    const touchStartYRef = useRef(null)
    const touchEndYRef = useRef(null)
    const touchStartedOnNoSwitchElement = useRef(false)
    const currentSectionRef = useRef(currentSection)
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < window.innerHeight)
    const sectionsListRef = useRef(['work', 'home', 'info'])

    //INFO SECTION TRANSITIONS
    const [currentInfoSection, setCurrentInfoSection] = useState('bio')

    const slideInRightTransition = useTransition(currentInfoSection, {
        config: { duration: 300 },
        from: { right: '100%' },
        enter: { right: '0%' },
        leave: { right: '-100%' }
    })

    const slideInLeftTransition = useTransition(currentInfoSection, {
        config: { duration: 300 },
        from: { left: '100%' },
        enter: { left: '0%' },
        leave: { left: '-100%' }
    })


    //MAIN TRANSITION STUFF
    const setLoadingTimeout = () => {
        loadingRef.current = true;
        setTimeout(() => {
            loadingRef.current = false
        }, 300)
    }

    const handleResize = useCallback(() => {
        const { innerWidth: width, innerHeight: height } = window

        if (!isMobileView && height > width) {
            setIsMobileView(true)
        } else if (isMobileView && width > height) {
            setIsMobileView(false)
        }
    }, [isMobileView])

    const touchMovingUp = () => touchEndYRef.current < touchStartYRef.current && (touchStartYRef.current - touchEndYRef.current) > 20

    const touchMovingDown = () => touchEndYRef.current > touchStartYRef.current && (touchEndYRef.current - touchStartYRef.current) > 20

    const handleSwitchSection = useCallback((e) => {

        const { current: loading } = loadingRef
        if (!loading && (!e?.srcElement?.classList?.value.includes('no-switch') || !touchStartedOnNoSwitchElement.current)) {
            const { current: list } = sectionsListRef
            const { current: currentSec } = currentSectionRef
            const index = list.findIndex(e => e === currentSec)
            if (list[index + 1] && (touchMovingDown() || (e && e.wheelDelta > 5))
            ) {
                setCurrentSection(list[index + 1])
                setLoadingTimeout()
            } else if (list[index - 1] && (touchMovingUp() || (e && e.wheelDelta < -5))
            ) {
                setCurrentSection(list[index - 1])
                setLoadingTimeout()
            }
        }

    }, [])


    const handleTouchStart = (e) => {
        touchStartYRef.current = e.targetTouches[0].pageY
        touchStartedOnNoSwitchElement.current = e?.srcElement?.className?.includes('no-switch') ? true : false
    }

    const handleTouchEnd = (e) => {
        touchEndYRef.current = e.changedTouches[0].pageY
        handleSwitchSection()
        touchStartYRef.current = null
        touchEndYRef.current = null
    }

    useEffect(() => {
        currentSectionRef.current = currentSection
        setLoadingTimeout()
    }, [currentSection])

    useEffect(() => {
        window.addEventListener('mousewheel', handleSwitchSection)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('mousewheel', handleSwitchSection)
            window.removeEventListener('resize', handleResize)
        }
    }, [handleSwitchSection, handleResize])

    return {
        currentSection,
        setCurrentSection,
        handleTouchStart,
        handleTouchEnd,
        loadingRef,
        isMobileView,
        currentInfoSection,
        setCurrentInfoSection,
        slideInRightTransition,
        slideInLeftTransition
    }
}

export default useTransitionContext;