import { useContext, useEffect } from 'react';
import AppContext from '../../context/AppContext';
import Blobs from '../Blobs/Blobs';
import './Home.css';


function Home() {
    const { currentSection } = useContext(AppContext)

    useEffect(() => {
        new Image().src = '../../assets/IMG_6145.jpg'
        new Image().src = '../../assets/mountains.png'
    }, [])

    return (
        <section className={`content-child-container ${currentSection === 'work' ? 'move-off-up' : currentSection === 'info' ? 'move-off-down' : ''}`} id='content-home-container'>
            <section className='home-child' id='home-left-container'>
                <Blobs color1='#04E762' color2='rgb(50, 164, 199)' duration='30s' position='bottom' />
            </section>
            <section className='home-child' id='home-right-container'>
                <Blobs color1='rgb(50, 164, 199)' color2='#04E762' duration='30s' position='top' />
            </section>
            <div id='content-home-title-container'>
                <div id='home-name-container'>
                    <h1 className='content-home-title'>STUART</h1>
                    <h1 className='content-home-title'>ERNSTSEN</h1>
                </div>

                <h2>web developer</h2>
            </div>
        </section>
    )
}

export default Home
