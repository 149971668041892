import {
  DiCss3,
  DiGithubBadge,
  DiReact,
  DiHtml5,
  DiPostgresql,
  DiJavascript1,
} from "react-icons/di";

import { SiRedux } from "react-icons/si";
import MySqlIcon from "../../../assets/icons/MySqlIcon";
import NextJsIcon from "../../../assets/icons/NextJsIcon";
import NodeIcon from "../../../assets/icons/NodeIcon";
import PuppetIcon from "../../../assets/icons/PuppetIcon";
import TailwindIcon from "../../../assets/icons/TailwindIcon";
import TypescriptIcon from "../../../assets/icons/TypescriptIcon";

const icons = {
  git: {
    name: "GitHub",
    component: <DiGithubBadge className="tech-icon" />,
  },
  react: {
    name: "React",
    component: <DiReact className="tech-icon" />,
  },
  css: {
    name: "CSS3",
    component: <DiCss3 className="tech-icon" />,
  },
  html: {
    name: "HTML5",
    component: <DiHtml5 className="tech-icon" />,
  },
  js: {
    name: "JavaScript",
    component: <DiJavascript1 className="tech-icon" />,
  },
  sql: {
    name: "PostgreSQL",
    component: <DiPostgresql className="tech-icon" />,
  },
  redux: {
    name: "Redux",
    component: <SiRedux className="tech-icon" />,
  },
  next: {
    name: "NextJs",
    component: <NextJsIcon className="tech-icon" />,
  },
  mySql: {
    name: "MySQL",
    component: <MySqlIcon className="tech-icon" />,
  },
  node: {
    name: "NodeJs",
    component: <NodeIcon className="tech-icon" />,
  },
  tailwind: {
    name: "Tailwind CSS",
    component: <TailwindIcon className="tech-icon" />,
  },
  typescript: {
    name: "Typescript",
    component: <TypescriptIcon className="tech-icon" />,
  },
  puppet: {
    name: "Puppeteer",
    component: <PuppetIcon className="tech-icon" />,
  },
};

export default icons;
