import { useTransition, animated } from "@react-spring/web";
import RedoRoundedIcon from "@material-ui/icons/RedoRounded";

const WorkSwitchButton = (props) => {
  const { handleSetNextProject, nextProjectName } = props;

  const rotateOneEighty = useTransition(nextProjectName, {
    config: { duration: 300 },
    from: {
      transform: "rotate(-180deg)",
      opacity: 0,
    },
    enter: {
      transform: "rotate(0deg)",
      opacity: 1,
    },
    leave: {
      transform: "rotate(180deg)",
      opacity: 0,
    },
  });

  return (
    <button id="switch-button" onClick={handleSetNextProject}>
      {rotateOneEighty((style, nextProjectName) => (
        <animated.div className="switch-button-inner-container" style={style}>
          <RedoRoundedIcon className="switch-arrow" fontSize="large" />
          <p id="switch-button-name">
            {nextProjectName} <span >next project</span>
          </p>
          <RedoRoundedIcon
            className="switch-arrow alt-switch-arrow"
            fontSize="large"
          />
        </animated.div>
      ))}
    </button>
  );
};

export default WorkSwitchButton;
