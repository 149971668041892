import { useContext } from "react";
import { animated } from "@react-spring/web";
import AppContext from "../../../context/AppContext";

const InfoContent = ({ handleCopyEmailToClipboard }) => {
  const { slideInLeftTransition } = useContext(AppContext);

  return slideInLeftTransition((style, section) =>
    section === "bio" ? (
      <animated.section id="info-bio" style={style}>
        <p>
          Hello, my name is <span>Stuart Ernstsen.</span> I am currently part
          and parcel of SilverOnyx/SkuProfit<span>.</span> SilverOnyx is among
          the top 100 Amazon sellers, and I help build and maintain internal
          tools to analyze sales performance of our catalog<span>.</span> I also
          work for their SkuProfit organization in which I build similar tools
          but for all Amazon sellers, that help aggregate, parse, and visualize
          complex sales reports<span>.</span> I come from a background of
          customer service, intermixed with computer science, and a sprinkle of
          art here and there<span>.</span> I am a Utah native who was born
          without the ability to pronounce the "t" in "mountains" and was raised
          on the holy nectar that is fry sauce. I am huge fan of all things
          creative and love helping others out in any capacity<span>.</span>{" "}
          Also<span>,</span> I wouldn't know how to survive if Taco Bell went
          out of business<span>...</span>
        </p>
      </animated.section>
    ) : (
      <animated.section id="info-contact" style={style}>
        <div className="contact-option-container">
          <a
            className="contact-option-item-container"
            href="https://www.linkedin.com/in/stuart-ernstsen/"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="contact-icon bi-linkedin"
              viewBox="0 0 16 16"
            >
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
            </svg>
            <p className="contact-option-label">Linkedin</p>
          </a>
          <hr />
          <div
            className="contact-option-item-container"
            onClick={handleCopyEmailToClipboard}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="contact-icon bi-envelope"
              viewBox="0 0 16 16"
            >
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
            </svg>
            <p className="contact-option-label">Email</p>
          </div>
          <hr />
          <a
            className="contact-option-item-container"
            href="https://docs.google.com/document/d/1rGXyPB2QS9zrrZvBMFcikaOTvh7JnVlJ8WsPrFjglPE/edit?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="contact-icon bi-file-text"
              viewBox="0 0 16 16"
            >
              <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
              <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
            </svg>
            <p className="contact-option-label">RESUME</p>
          </a>
        </div>
      </animated.section>
    )
  );
};

export default InfoContent;
