import { useContext, useEffect, useState } from 'react';
import { useTransition, animated } from '@react-spring/web';
import InfoTitle from './children/InfoTitle';
import InfoContent from './children/InfoContent';
import InfoSwitchButton from './children/InfoSwitchButton';
import AppContext from '../../context/AppContext';
import './Info.css';
import profilePic from '../../assets/IMG_6145.jpg';
import mountains from '../../assets/mountains.png';
import { IoMdAlert } from 'react-icons/io';

function Info() {
    const { currentSection, setCurrentInfoSection, currentInfoSection } = useContext(AppContext)
    const [showEmailNote, setShowEmailNote] = useState(false)

    const handleCopyEmailToClipboard = () => {
        navigator.clipboard.writeText('stuartErnstsen@gmail.com')
        setShowEmailNote(true)
    }

    useEffect(() => {
        let emailTimer;
        if (showEmailNote) {
            emailTimer = setTimeout(() => {
                setShowEmailNote(false)
            }, 5000)
        }
        return () => {
            if (emailTimer) {
                clearTimeout(emailTimer)
            }
        }
    }, [showEmailNote])



    const infoTransition = useTransition(currentSection, {
        config: {
            duration: 300,
        },
        from: {
            top: '-100%'
        },
        enter: {
            top: '0%'
        },
        leave: {
            top: '-100%'
        }
    })

    return (
        infoTransition((style, currentSection) =>
            (currentSection === 'info') && (
                <animated.section style={style} className='content-child-container' id='content-info-container'>
                    <div className='alt-section-background info-section-background'></div>
                    <section className='info-child' id='info-left-container'>
                        <img className='slide-in-left' id='info-left-background' src={profilePic} alt='very-handsome' />
                    </section>
                    <section className='info-child' id='info-right-container'>
                        <div id='email-notification'
                            className={showEmailNote ? 'show-email-note' : ''}>
                            <IoMdAlert id='email-note-icon' />
                            <div id='email-note-text'>
                                <span>'stuartErnstsen@gmail.com'</span>
                                <p>copied to clipboard!</p>
                            </div>
                        </div>
                        <InfoSwitchButton setCurrentInfoSection={setCurrentInfoSection} currentInfoSection={currentInfoSection} />
                        <div className='info-right-child' id='info-right-top-container'>
                            <InfoTitle />
                        </div>
                        <div className='info-right-child' id='info-right-bottom-container'>
                            <InfoContent handleCopyEmailToClipboard={handleCopyEmailToClipboard} />
                        </div>
                    </section>
                    <img className='mountain-background slide-in-bottom' src={mountains} alt='mountains' />
                </animated.section>
            )
        )

    )
}

export default Info
