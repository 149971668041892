import AppContext from './AppContext';
import useTransitionContext from './contextHooks/useTransitionContext';

const AppProvider = (props) => {
    //Context that will store all the global state needed for css transitions within the app
    const transitionContext = useTransitionContext()
    return (
        <AppContext.Provider value={{
            ...transitionContext
        }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default AppProvider;