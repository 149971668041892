import { useContext, useEffect, useState } from 'react';
import AppContext from './context/AppContext'
import Info from './components/Info/Info';
import Home from './components/Home/Home';
import Work from './components/Work/Work';
import HouseRoundedIcon from '@material-ui/icons/HouseRounded';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import './App.css';

function App() {
  const {
    currentSection,
    setCurrentSection,
    handleTouchStart,
    handleTouchEnd,
    loadingRef,
  } = useContext(AppContext)

  const [theme, setTheme] = useState('')
  const [showMobileNav, setShowMobileNav] = useState(false)

  const handleNavClick = (sectionToMoveTo) => {
    if (!loadingRef.current) {
      setCurrentSection(sectionToMoveTo)
      // if (showMobileNav) {
      //   setShowMobileNav(false)
      // }
    }
  }

  useEffect(() => {
    setTheme(`nav-${currentSection}-theme`)
  }, [currentSection])


  return (
    <div className={`default ${theme}`}>
      <nav className={`main-nav-side`}>
        <div id='nav-background'></div>
        <div className={`main-nav-item-container ${currentSection === 'info' ? 'nav-container-active' : ''}`} onClick={() => handleNavClick('info')}>
          <AccountBoxRoundedIcon className='nav-icon' />
          <div className={`nav-indicator ${currentSection === 'info' && 'active-indicator'}`}></div>
        </div>
        <div className={`main-nav-item-container ${currentSection === 'home' ? 'nav-container-active' : ''}`} onClick={() => handleNavClick('home')}>
          <HouseRoundedIcon className='nav-icon' />
          <div className={`nav-indicator ${currentSection === 'home' && 'active-indicator'}`}></div>
        </div>
        <div className={`main-nav-item-container ${currentSection === 'work' ? 'nav-container-active' : ''}`} onClick={() => handleNavClick('work')}>
          <DashboardRoundedIcon className='nav-icon' />
          <div className={`nav-indicator ${currentSection === 'work' && 'active-indicator'}`}></div>
        </div>
      </nav>
      <div
        className={showMobileNav ? 'show-mobile-nav-back' : ''} id='mobile-nav-deactivate-background'
        onClick={() => setShowMobileNav(false)} />
      <nav className={`${showMobileNav ? 'show-nav' : ''}`} id='mobile-nav'>
        <button id='mobile-nav-button' onClick={() => setShowMobileNav(old => !old)}>
          <div id='cake'></div>
        </button>
        <ul>
          <li className={currentSection === 'info' ? 'mobile-active' : ''} onClick={() => handleNavClick('info')}>INFO</li>
          <li className={currentSection === 'home' ? 'mobile-active' : ''} onClick={() => handleNavClick('home')}>HOME</li>
          <li className={currentSection === 'work' ? 'mobile-active' : ''} onClick={() => handleNavClick('work')}>WORK</li>
        </ul>
      </nav>
      <main onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        <Info />
        <Home />
        <Work />
        <img id='content-background-dynamic' src="https://images.unsplash.com/photo-1552084117-56a987666449?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2134&q=80" alt='groovy-background' />
        {/* <div id='content-background-dynamic' /> */}
      </main>
    </div >
  );
}

export default App;
