import { useContext } from 'react';
import { animated } from '@react-spring/web';
import AppContext from '../../../context/AppContext';

const InfoTitle = () => {

    const { slideInRightTransition } = useContext(AppContext)


    return (

        slideInRightTransition((style, section) =>
            (section === 'bio') ? (
                <animated.h2 style={style} id='bio-big-text'>
                    BIO
                </animated.h2>

            ) : (
                <animated.h2 style={style}>
                    CONTACT
                </animated.h2>
            )
        )
    )
}

export default InfoTitle